import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CreateUser from "./components/CreateUser";
import SetToken from './components/SetToken';
import NavBar from './components/NavBar';
import './styles/index.css';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/" element={<><NavBar /> <CreateUser /></>}/>
          <Route path="/settoken" element={<><NavBar /> <SetToken /></>}/>
        </Routes>
        <ToastContainer />
      </div>
    </BrowserRouter>
  );
}

export default App;
