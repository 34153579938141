import React from "react";
import styles from '../styles/navbar.module.css';
import { Link } from "react-router-dom";

export default function NavBar(){
    return(
        <>
            <div className={styles.navBox}>
                <div className={styles.navContainer}>
                    <div className={styles.navItem}>
                        <h3 className={styles.h3}>
                            AlmiranteHydra
                        </h3>
                    </div>
                    <div className={styles.navItem}>
                        <Link to='/' className={styles.a}>CreateUser</Link>
                        <Link to='/settoken' className={styles.a}>SetToken</Link>
                    </div>
                </div>
            </div>
        </>
    )
}