import React, { useState } from "react";
import styles from '../styles/createuser.module.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import serverConfig from "./serverconfig.js";

export default function SetToken(){

    const [inputs, setInputs] = useState({});

    const handleSubmit = () =>{
        if(!inputs.tokenauth || !inputs.newtoken){
            toast.error('Preencha os campos corretamente');
            return;
        }

        const dataToSend = {
            ...inputs
        };

        const createUserFetch = async () =>{
            const response = await axios.post(`${serverConfig.apiUrl}addToken.php`, dataToSend);
            if(response.data.error){
                toast.error(response.data.message);
            }
            else{
                toast.success(response.data.message);
                const inputs = document.querySelectorAll('input[type="text"]');
                inputs.forEach((input) =>{
                    input.value = '';
                })
            }
        }
        createUserFetch();
    }

    return(
        <>
            <div className={styles.box}>
                <div className={styles.container}>
                    <div className={styles.title}>
                        <h3 className={styles.h3}>Set Token</h3>
                    </div>
                    <div className={styles.inputs}>
                        <label className={styles.label} htmlFor="tokenauth">TokenAdmin</label>
                        <input className={styles.input} type="text" placeholder="Token Auth" id="tokenauth" onChange={(e) => setInputs({...inputs, tokenauth: e.target.value})} />

                        <label className={styles.label} htmlFor="login">New Token</label>
                        <input className={styles.input} type="text" placeholder="Token to add" id="login" maxLength='100' onChange={(e) => setInputs({...inputs, newtoken: e.target.value})} />

                        <div className={styles.buttons}>
                            <button type="button" className={styles.button} onClick={handleSubmit}>Add Token</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}