import React, { useState, useEffect } from "react";
import styles from '../styles/createuser.module.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import serverConfig from "./serverconfig.js";

export default function CreateUser(){
    const [date, setDate] = useState('');
    const [inputs, setInputs] = useState({});

    const handleSubmit = () =>{
        if(!inputs.tokenauth || !inputs.login || !inputs.password){
            toast.error('Preencha os campos corretamente');
            return;
        }

        const dataToSend = {
            ...inputs
        };

        const createUserFetch = async () =>{
            const response = await axios.post(`${serverConfig.apiUrl}createUser.php`, dataToSend);
            if(response.data.error){
                toast.error(response.data.message);
            }
            else{
                toast.success(response.data.message);
                const inputs = document.querySelectorAll('input[type="text"]');
                inputs.forEach((input) =>{
                    input.value = '';
                })
            }
        }
        createUserFetch();
    }

    const handleAddDays = () =>{
        const futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + 30);

        const futureDay = String(futureDate.getDate()).padStart(2, '0');
        const futureMonth = String(futureDate.getMonth() + 1).padStart(2, '0');
        const futureYear = futureDate.getFullYear();
        const futureFormattedDate = `${futureYear}-${futureMonth}-${futureDay}`;
        setDate(futureFormattedDate);
        setInputs({...inputs, date: futureFormattedDate});
    };

    return(
        <>
            <div className={styles.box}>
                <div className={styles.container}>
                    <div className={styles.title}>
                        <h3 className={styles.h3}>Create User</h3>
                    </div>
                    <div className={styles.inputs}>
                        <label className={styles.label} htmlFor="tokenauth">TokenAdmin</label>
                        <input className={styles.input} type="text" placeholder="Token Auth" id="tokenauth" onChange={(e) => setInputs({...inputs, tokenauth: e.target.value})} />

                        <label className={styles.label} htmlFor="login">Login</label>
                        <input className={styles.input} type="text" placeholder="Create login" id="login" maxLength='18' onChange={(e) => setInputs({...inputs, login: e.target.value})} />

                        <label className={styles.label} htmlFor="password">Password</label>
                        <input className={styles.input} type="text" placeholder="Create password" id="password" maxLength='18'  onChange={(e) => setInputs({...inputs, password: e.target.value})}/>

                        <label className={styles.label} htmlFor="date">Date</label>
                        <input className={styles.input} type="date" id="date"  value={date} onChange={(e) => {
                            setInputs({...inputs, date: e.target.value})
                            setDate(e.target.value)
                        }}/>

                        <button className={styles.addDays} onClick={handleAddDays}>+30 Days</button>    

                        <div className={styles.buttons}>
                            <button type="button" className={styles.button} onClick={handleSubmit}>Create</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}